













import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'
import { MintingViewModel } from '../viewmodels/minting-viewmodel'

@Observer
@Component
export default class extends Vue {
  @Inject({}) vm!: MintingViewModel
}
